/* Global menu
----------------------------*/

$font-family_1: 'Century Gothic Bold', sans-serif;
$font-family_2: 'Century Gothic', sans-serif;

@font-face {
	font-family: 'Century Gothic';
  font-display: swap;
	src: url('/dist/fonts/gothic/CenturyGothic.ttf');
	src: url('/dist/fonts/gothic/CenturyGothic.ttf') format('truetype'),;
}
@font-face {
	font-family: 'Century Gothic Bold';
	src: url('/dist/fonts/gothic/GOTHICB0.ttf');
  font-display: swap;
	src: url('/dist/fonts/gothic/GOTHICB0.ttf') format('truetype'),;
}
@keyframes show {
	from {
		opacity: 0;
		transform: translate3d(-600px, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	overflow-x: hidden !important;
}
h1 {
	font-family: $font-family_1;
}
h2 {
	font-family: $font-family_1;
}
h3 {
	font-family: $font-family_1;
}
h4 {
	font-family: $font-family_1;
}
h5 {
	font-family: $font-family_1;
}
h6 {
	font-family: $font-family_1;
}
p {
	font-family: $font-family_2;
}
a {
	font-family: $font-family_2;
}
ul {
	li {
		a {
			font-family: $font-family_2;
		}
		font-family: $font-family_2;
	}
}
.js {
	.js-tabcontent {
		section {
			display: none;
		}
	}
	.js-scroll {
		opacity: 0;
		transform: translate3d(-600px, 0, 0);
		transition: .3s;
	}
	.js-scroll.ativo {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
.js-tabcontent {
	section.ativo {
		display: block !important;
		animation: show .4s forwards;
	}
}

// MENU

header {
  position: fixed;
  top: 10px;
  left: 10%;
  width: 80%;
  transition: 400ms;
  text-align: -webkit-center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  display: block;
z-index: 99;
padding:15px;
}


header nav.navbar .ajuste-brand {
  background-color: transparent;
  margin-left: 0px;
  padding: 4px 4px;
}

.space_ajust {
  position: relative;
  padding: 2px 8px;
  width: 33%;
  text-align: center;
  margin-right: -67%;
}

.ajuste-items {
  margin-right: 0px;
}

.languageSection {
  margin-top: 10px;
  background-color: #eeeeee;
  border: none;
  border-radius: 12px;
  padding: 1px;
}

.languageSection a{
  color: #000;
  background-color: #eeeeee;
  border-radius: 12px;
  padding: 2px 18px;
  text-decoration: none;
}
.languageSection a:hover{
  color: #000;
  text-decoration: none;
}

.languageSection .active {
  background: #fff;
}

header #nav-top {
  background-color: #fff;
}

nav.navbar {
  background-color: transparent;
  box-shadow: unset !important;
  padding-bottom: 0;
  width: 100%;
}

.dropdown {
	&:hover {
		>.dropdown-menu {
			display: block;
		}
	}
}


nav.navbar {
  .ajuste-brand {
    background-color: transparent;
    margin-left: 0px;
    padding: 5px 5px;
  }
  background-color: transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  padding-bottom: 0;
  width: 100%;
  .navbar-brand {
    img {
      margin-left: 75px;
      transition: 400ms;
      width: 191px;
      max-height: 4em;
      &:active {
        transform: scale(.9);
      }
    }
  }
  #navbarNavDropdown {
    ul.navbar-nav {
      li.nav-item {
        a.nav-link {
          position: relative;
          font-size: 1em;
          color: #000!important;
          font-weight: 500;
          transition: 400ms;
          padding: 0;
          &:hover {
            background-color: transparent;
            border-radius: 6px;
            color: #000 !important;
          }
          &:after {
            position: absolute;
            bottom: 0;
            left: 0%;
            width: 0;
            height: 2px;
            
          }
        }
        margin: 4px 10px;
      }
      li.navbar-link {
        a.nav-link {
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
      li.navbar-link.dropdown {
        &:hover {
          >.dropdown-menu {
            display: block;
            -webkit-animation: slide-down 1s ease;
            animation: slide-down 1s ease;
            transform: scale(1);
            transition: 300ms;
          }
        }
        .dropdown-menu {
          z-index: -1;
          margin: 0;
          border: 0;
          border-radius: 0 0 4px 4px;
        }
        a {
          &:hover {
            color: #000;
            transform: scale(1.1);
          }
          &:active {
           
            color: #000;
          }
        }
      }
    }
  }
}


@-webkit-keyframes slide-down {
  0% {
      transform: translateY(-100%);
      opacity: 0
  }

  to {
      transform: translateY(0);
      opacity: 1
  }
}

@keyframes slide-down {
  0% {
      transform: translateY(-100%);
      opacity: 0
  }

  to {
      transform: translateY(0);
      opacity: 1
  }
}

  .dropdown-menu {
    padding: 0 !important;
    margin: 0 !important;
    transition: 400ms;
}

.navbar-nav a:hover {
  color: #000;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #000;
  text-decoration: none;
  background-color: #4caf50 !important;
  transition: 400ms;
}

.nav-link {
  display: inline-block !important;
  text-decoration: none;
}

.nav-link::after {
  content: '' !important;
  display: block !important;
  width: 0 !important;
  height: 3px !important;
  background: #4caf50 !important;
  transition: width .2s;
}

.nav-link:hover::after {
  width: 100% !important;
  transition: width .3s;
}

header.header-fixed {
  position: fixed;
  top: 15px;
  transition: 400ms;
}

header.header-fixed nav.navbar {
  align-items: center;
}

header.header-fixed nav.navbar .ajuste-brand {
  padding: 13px;
}

header.header-fixed nav.navbar .navbar-brand img {
  transition: 400ms;
  width: 191px;
  max-height: 3em
}

.rede{
  margin-left: 25px;
}

.social img{
  width: 23px;
  margin: 4px;
  transform: scale(1);
  transition: 300ms;
}

.social img:hover {
  transform: scale(1.1);
  transition: 300ms;
}

.social:hover {
  text-decoration: none;
}

.selo{
  margin: 0 20px;
  width: 90px;
  height: 77px;
}

@media only screen and (max-width:1441px) {
  header.header-fixed {
      position: fixed;
      top: 15px;
      transition: 400ms;
  }

  header {
      left: 8%;
  }

  .ajuste-items-1 {
      margin-right: 12.5%;
  }

  .ajuste-items {
      margin-right: 0;
  }
}

@media only screen and (max-width:1294px) {
  .ajuste-items-1 {
      margin-right: 12.5%;
  }

  .ajuste-items {
      margin-right: 0%;
  }

}

@media only screen and (max-width:1148px) {
  header {
      width: 100%;
      top: 0px;
      left: 0;
  }

  header.header-fixed {
      position: fixed;
      top: -35px;
      transition: 400ms;
  }

  header nav.navbar {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
      padding-bottom: 0;
      width: 100%;
      border-radius: 10px;

  }

  header nav.navbar .ajuste-brand {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      padding: 17px;

  }
}

@media only screen and (max-width:992px) {

  header nav.navbar #navbarNavDropdown ul.navbar-nav li.nav-item a.nav-link {
      font-size: .9em;
  }

  header nav.navbar button.navbar-toggler {
      margin-bottom: 3px
  }

  header nav.navbar #navbarNavDropdown ul.navbar-nav li.navbar-link.dropdown:hover>.dropdown-menu {
      -webkit-animation: ease;
      animation: ease
  }

}

@media only screen and (max-width:769px) {
      
  header nav.navbar {
      padding-bottom: .5em;
      align-items: center
  }

  header {
    width: 100%;
      position: absolute;
      top: 0px;
  }
  .space_ajust {
      position: relative;
      padding: 2px 8px;
      width: 100%;
      margin-bottom: 0px;
      border-radius: 0;
      z-index: 2001;
  }

  header.header-fixed {
      position: fixed;
      transition: 400ms;
  }
  
}

@media only screen and (max-width:575px) {

  header nav.navbar .ajuste-brand {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      padding: 5px;
  }

  header #nav-top ul li {
      font-size: .85em
  }

  header nav.navbar .navbar-brand {
      margin-right: .2em
  }

  header nav.navbar .navbar-brand img {
      width: 80%;
      max-height: 3em;
      margin-left: 5px;
  }

  header.header-fixed nav.navbar .navbar-brand img {
      transition: 400ms;
      width: 82%;
      max-height: 3em
  }

  header nav.navbar {
      padding: 0 1rem !important;
  }

  header nav.navbar .ajuste-brand {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
  }

  header {
      top: 1px;
      width: 100%;
  }

  header.header-fixed {
    top: 3px;
    width: 100%;
}

  header nav img{
    width: 50px;
  }
  .space_ajust {
      position: relative;
      padding: 2px 8px;
      width: 100%;
      height: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 0px;
      border-radius: 0;
      z-index: 2001;
  }

  .selo{
    margin: 12px 20px;
  }

}

/* footer */


footer {
  background-color: #000;
  position: relative;
  z-index: 10;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  color: #fff;
}

footer ul li a,footer ul li {
  color: #fff;
  list-style: none;
}

footer p a:hover {
  color: #fff;
}

footer .uls{
border-left: 2px solid #4caf50;
}


footer p, footer p a {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
}

footer .contato {
  color: #fff;
  text-align: center;
  font-size: 1rem;
}


@media (max-width: 575px) {
  footer .uls{
      text-align: center;
  }
  img{
    width: 100%;
  }

}

